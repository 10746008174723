var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "formContent" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "dialog-form add-enclousre-form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "left"
              }
            },
            [
              false
                ? _c(
                    "el-form-item",
                    { attrs: { label: " 区域编号" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.form.id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "id", $$v)
                          },
                          expression: "form.id"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: " 区域名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeAlarmType },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "type",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.type"
                      }
                    },
                    _vm._l(_vm.typeList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属企业",
                    rules: [{ required: true, message: "不能为空" }]
                  }
                },
                [
                  _c("companySelect", {
                    ref: "companySelect",
                    attrs: {
                      clearable: true,
                      companyTree: _vm.companyList,
                      valueName: _vm.valueNameAdd,
                      value: _vm.valueAdd
                    },
                    on: { getValue: _vm.getAddGroupId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "报警类型", prop: "alarmType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.typeDisabled
                      },
                      model: {
                        value: _vm.form.alarmType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "alarmType", $$v)
                        },
                        expression: "form.alarmType"
                      }
                    },
                    _vm._l(_vm.alarmTypeList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域颜色" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeColorType },
                      model: {
                        value: _vm.form.color,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "color", $$v)
                        },
                        expression: "form.color"
                      }
                    },
                    _vm._l(_vm.colorList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.form.type !== "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "报警车辆数阈值", prop: "threshold" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c("el-input", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.form.threshold,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "threshold",
                                      _vm._n(
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    )
                                  },
                                  expression: "form.threshold"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 4 } }, [_vm._v("辆")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "报警提示内容", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", maxlength: "200" },
                        model: {
                          value: _vm.form.content,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "content",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.content"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.type === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "预警提前量", prop: "threshold" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "单位：米" },
                                model: {
                                  value: _vm.form.threshold,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "threshold",
                                      _vm._n(
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    )
                                  },
                                  expression: "form.threshold"
                                }
                              })
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 4 } }, [_vm._v("米")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.drawMap },
                model: {
                  value: _vm.form.areaType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "areaType", $$v)
                  },
                  expression: "form.areaType"
                }
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("圆形")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("矩形")]),
                _c("el-radio", { attrs: { label: 3 } }, [_vm._v("多边形")]),
                _c("el-radio", { attrs: { label: 4 } }, [_vm._v("线段")])
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "clearMap",
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.clearType }
              },
              [_vm._v("点击清除")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "definedMap", attrs: { id: "container" } })
      ])
    ]),
    _c("div", { staticClass: "dialog-btn" }, [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", size: "small" },
              on: {
                click: function($event) {
                  return _vm.clsoeDia(true)
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }