var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    attrs: {
      background: "",
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 20, 30, 40],
      layout: "total, sizes, prev, pager, next, jumper",
      total: _vm.pageTotal
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange,
      "update:currentPage": function($event) {
        _vm.currentPage = $event
      },
      "update:current-page": function($event) {
        _vm.currentPage = $event
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }